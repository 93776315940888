<template>
    <painel titulo="Templates" icone="pi pi-key" :refreshFunction="atualizar" v-if="mostrarListagem">
        <tabela :data="templates" dataKey="templateId" :globalFilterFields="['codigo', 'descricao']" stateKey="dt-templates">
            <template #botoes>
                <btn-inserir></btn-inserir>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :template="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="codigo" header="Código" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codigo }}
                    </template>
                </Column>
                <Column field="descricao" header="Descrição" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.descricao }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import TemplatesServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            templates: null,
        };
    },

    methods: {
        obterTemplates() {
            this.$store.dispatch('addRequest');
            TemplatesServices.obterTodos().then((response) => {
                if (response?.success) {
                    this.templates = response.data;
                } else {
                    this.templates = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterTemplates();
        },
    },

    mounted() {
        this.obterTemplates();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Templates';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Templates') {
                if (this.$store.getters.atualizar) {
                    this.obterTemplates();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
